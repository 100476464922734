body {
  background: linear-gradient(0deg, rgba(30, 40, 41, 0.29), rgba(30, 40, 41, 0.29)), url(img/rec1.png);
  width: 100%;
  background-repeat: repeat-x;
  background-color: black;
  color: white;
}

.pointer {
  cursor: pointer;
}

.companyName {
  font-family: 'Pacifico';
  font-style: normal;
  font-weight: 400;
  font-size: 6.4vw;
  line-height: 11.25vw;
}

.card {
  color: black;
}

.presents {
  font-family: 'Six Caps';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
}
.card-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 36px;
}

.card-text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
}

.followInstagramButton {
  /* width: 256px;
  height: 68px; */
  color: white;
  font-family: 'Six Caps';
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 47px;
  background: #f0ad50;
  border-color: #f0ad50;
  border-radius: 10px;
}

.followInstagramButton:hover,
.followInstagramButton:focus,
.followInstagramButton:active,
.followInstagramButton.active,
.open > .dropdown-toggle.followInstagramButton {
  color: #fff;
  background-color: #f0ad50;
  border-color: #f0ad50; /*set the color you want here*/
}

.filmFestival {
  font-family: 'Six Caps';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 63px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main {
  position: fixed;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}
